body {
    background-color: #000112 !important;
    font-family: "Lucida Console", Monaco, monospace !important;
    color: #20c997 !important;
}

a {
    color: #20c997 !important;
}

a:hover {
    color: #c92052 !important;
}